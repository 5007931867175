<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-16 18:19:55
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-17 17:20:13
-->
<template>
    <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px;" :width="1000" v-model="visible" @ok="toSubmit" okText="提交" :maskClosable="false">
        <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
            <a-form-model-item label="经销商" prop="">
                <a-cascader 
                    v-model="checkedKeys" 
                    placeholder="请选择" 
                    :options="treeData"
                    @change="onCheck"
                    change-on-select
                    :field-names="{ label: 'title', value: 'id', children: 'children' }"
                    >
                </a-cascader>
            </a-form-model-item>
            <a-form-model-item label="修改类型" prop="(customerName">
                <a-row :gutter="8">
                    <a-col :md="6">
                        <a-radio-group v-model="rowData.amountType" @change="onChange">
                            <a-radio :value="1">增加</a-radio>
                            <a-radio :value="2">减少</a-radio>
                        </a-radio-group>
                    </a-col>
                    <a-col :md="6">
                        <a-input-number v-model="rowData.dealIntegral" style="width: 100%" placeholder="请输入积分"></a-input-number>
                    </a-col>
                </a-row>
            </a-form-model-item>
            <a-form-model-item label="当前积分" prop="dealIntegral">
                <a-input disabled v-model="rowData.integral" placeholder="当前积分"></a-input>
            </a-form-model-item>
            <a-form-model-item label="修改原因" prop="remark">
                <a-input v-model="rowData.remark" placeholder="修改原因"></a-input>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import { editCustomerPayInfo, selectByIdCustomerPayInfo, addCustomerPayInfo } from '../api/CustomerPayInfoApi'
export default {
    data() {
        return {
            visible: false,
            handle: 'add',
            rowData: {},
            // 表单验证
            formRule: {
                customerName: [
                    { required: true, message: '请输入经销商名称', trigger: 'blur' }
                ],
                dealIntegral: [
                    { required: true, message: '请输入当前积分', trigger: 'blur' }
                ],
                remark: [
                    { required: true, message: '请输入修改原因', trigger: 'blur' }
                ],
            },
            checkedKeys: [],
            treeData: [],
        }
    },
    methods: {
        /**
         * 获取行数据
         */
        setRowData(row, handle){
            this.handle = handle
            this.visible = true
            this.axios.post('/api/dealer/dealer/dealerInfo/getTree').then(res => {
                this.treeData = res.body
            })
            if(handle == 'edit') {
                this.$set(this.rowData, 'amountType', 1)
                this.getDetail(row.dealerId)
            }
        },

        getDetail(dealerId) {
            selectByIdCustomerPayInfo(dealerId).then(res => {
                this.rowData.integral = res.body.integral
                this.checkedKeys = [res.body.mhiacAreaId, res.body.firmId, res.body.dealerId]
                this.rowData.dealerId = res.body.dealerId
            })
        },

        onCheck(checkedKeys) {
            let dealerId = checkedKeys[checkedKeys.length - 1]
            this.rowData.dealerId = dealerId
            this.getDetail(dealerId)
        },

        onChange(val) {

        },
        /**
         * 表单提交
         */
        toSubmit() {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }
                if(!this.rowData.dealerId) {
                    return this.$notification.warning({ message: '请选择经销商'})
                }
                const res = this.handle === 'add' ? await addCustomerPayInfo(this.rowData) : await addCustomerPayInfo(this.rowData)
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.visible = false
                    this.rowData = {}
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        }
    },
    created() {

    },

}
</script>

<style lang="less" scoped>

</style>
